


/**
 * 
 * @typedef {object} Account
 *  @property {number} id
*  @property {string} createdAt
*  @property {string} updatedAt
*  @property {string} email
*  @property {boolean} verified
*  @property {{
*  type: 'institute' | 'student' | 'parent' | 'employee'
* }}role
* 
*/

import { CustomIcon } from "src/components/icon";
import Iconify from "src/components/iconify";
import routes from "src/utils/routes";
import { icon } from "./icon";



/**
 * 
 * @param {Account} account 
 * @param {Account} institute 
 * @returns 
 */
export const getStudentNavigation = (account, institute) => [
  {
    title: 'dashboard',
    path: routes.HomePage,
    icon: icon('ic_analytics'),
  },
  {
    title: 'Messaging',
    path: routes.MessagesPage,
    icon: <Iconify icon='eva:settings-2-outline' />,
    items: [{
      title: 'Institute Chat Room',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}`)
    },
    {
      title: 'Class Chat Room',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}::class::${account?.props?.class?.id}`)
    }, {
      title: 'Direct Messaging',
      path: routes.DirectMessagingPage
    }]
  },
  {
    title: `${account.props?.class?.name} Timetable`,
    path: routes.ActiveClassTimetablePage,
    icon: <CustomIcon icon='png:im.im_school' />,
  },
  // {
  //   title: 'settings',
  //   path: routes.SettingsPage,
  //   icon: <Iconify icon='eva:settings-2-outline'/>,
  // },

]

/**
 * 
 * @param {Account} account 
 * @param {Account} institute 
 * @returns 
 */
export const getParentNavigation = (account, institute) => {
  const chatRooms = [
    {
      title: 'Institute Chat Room',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}`)
    },

    {
      title: 'Chat With Admin',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}::parent::${account.id}::admin`)
    },
    {
      title: 'Direct Messaging',
      path: routes.DirectMessagingPage
    }

  ]
  if (Array.isArray(account.props?.family?.students)) {
    Array.from(account.props?.family?.students).filter((student) => student.class).forEach((({ class: activeClass }) => {
      chatRooms.push(
        {
          title: `${activeClass.name} Chat Room`,
          path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}::class::${activeClass.id}`)
        },
      )
    }))

  }
  return [
    {
      title: 'dashboard',
      path: routes.HomePage,
      icon: icon('ic_analytics'),
    },
    {
      title: 'Messaging',
      path: routes.MessagesPage,
      icon: <Iconify icon='eva:settings-2-outline' />,
      items: chatRooms
    },
    {
      title: 'Live Class',
      path: routes.SettingsPage,
      icon: <Iconify icon='eva:settings-2-outline' />,
      items: [{
        title: 'Institute Live Class',
        path: routes.LiveClassRoomPage.replace(':room_id', `institute::${institute.id}`)
      }]
    }
  ]
}


/**
* @param {Account} account 
* @param {Account} institute 
* @returns 
*/
export const getTeacherNavigation = (account, institute) => {

  const chatRooms = [
    {
      title: 'Institute Chat Room',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}`)
    },
    {
      title: 'Direct Messaging',
      path: routes.DirectMessagingPage
    }
  ]
  if (account.props?.class) {
    chatRooms.push(
      {
        title: `${account.props?.class.name} Chat Room`,
        path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}::class::${account.props?.class.id}`)
      },

    )
  }
  return [
    {
      title: 'dashboard',
      path: routes.HomePage,
      icon: icon('ic_analytics'),
    },
    {
      title: 'Classes',
      path: routes.ClassesPage,
      icon: <Iconify icon='eva:book-outline' />,
      items: [
        {
          title: 'All Classes',
          path: routes.ClassAllPage,
        },
        {
          title: 'New Class',
          path: routes.ClassAddNewPage,
        },
      ]
    },
    {
      title: 'Lessons',
      path: routes.LessonsPage,
      icon: icon('ic_user'),
      items: [
        {
          title: 'Lesson Plans',
          path: routes.LessonPlanAllPage,
        }
      ]
    },
    {
      title: 'Subjects',
      path: routes.SubjectPage,
      icon: icon('ic_user'),
      items: [
        {
          title: 'Classes with Subjects',
          path: routes.ClassWithSubjectsPage,
        },
        {
          title: 'Assign Subjects',
          path: routes.AssignClassToSubjectsPage,
        },
      ]
    },
    {
      title: 'Students',
      path: routes.StudentPage,
      icon: icon('ic_user'),
      items: [
        {
          title: 'All Student',
          path: routes.StudentAllPage,

        },

        {
          title: 'Promote Students',
          path: routes.StudentPromotionPage,

        }
      ]
    },

    {
      title: 'Assessments',
      path: routes.AssessmentsPage,
      icon: icon('ic_user'),
      items: [
        {
          title: 'Create New Assessment',
          path: routes.AssessmentAddNewPage,
        },
        {
          title: 'All Assessment',
          path: routes.AssessmentAllPage,
        },

        {
          title: 'Add/Update Assessment Group',
          path: routes.AssessmentGroupAllPage,
        },
        {
          title: 'Assign Assessment to Group',
          path: routes.AssignAssessmentToGroupPage,
        },
      ]
    },

    {
      title: 'Exams',
      path: routes.ExamsPage,
      icon: icon('ic_user'),
      items: [
        {
          title: 'Create New Exam',
          path: routes.ExamAddNewPage,
        },
        {
          title: 'All Exam',
          path: routes.ExamAllPage,
        },
        {
          title: 'Add/Update Exam Mark',
          path: routes.ExamMarkingPage,
        },
        {
          title: 'Add/Update Exam Group',
          path: routes.ExamGroupAllPage,
        },
        {
          title: 'Assign Exam to Group',
          path: routes.AssignExamToGroupPage,
        },
        {
          title: 'Result Card',
          path: routes.ExamResultPage,

        }
      ]
    },
    {
      title: 'Messaging',
      path: routes.MessagesPage,
      icon: <Iconify icon='eva:settings-2-outline' />,
      items: chatRooms
    },
    {
      title: 'Live Class',
      path: routes.SettingsPage,
      icon: <Iconify icon='eva:settings-2-outline' />,
      items: [{
        title: 'Institute Live Class',
        path: routes.LiveClassRoomPage.replace(':room_id', `institute::${institute.id}`)
      }]
    },
    {
      title: 'Attendance',
      path: routes.AttendancesPage,
      icon: <CustomIcon icon='png:im.im_school' />,
      items: [
        {
          title: 'Mark Students Attendance',
          path: routes.MarkStudentsAttendancePage,
        },
        {
          title: 'Mark Employees Attendance',
          path: routes.MarkEmployeesAttendancePage,
        },

        {
          title: 'Class wise Report',
          path: routes.ClasswiseReportPage,
        },

        {
          title: 'Employees Attendance Report',
          path: routes.EmployeesAttendanceReportPage,
        },
        {
          title: 'Students Attendance Report',
          path: routes.StudentsAttendanceReportPage,
        },
      ]
    },
    {
      title: 'Timetable',
      path: routes.TimetablesPage,
      icon: <CustomIcon icon='png:im.im_school' />,
      items: [
        {
          title: 'Weekdays',
          path: routes.WeekdaysTimetablePage,

        },
        {
          title: 'Time Period',
          path: routes.TimePeriodTimetablePage,

        },
        {
          title: 'Class Rooms',
          path: routes.ClassRoomsTimetablePage,

        },
        {
          title: 'Create Timetable',
          path: routes.CreateTimetablePage,

        },
        {
          title: 'Generate For Class',
          path: routes.ClassTimetablePage,

        },
        {
          title: 'Generate For Teacher',
          path: routes.TeacherTimetablePage,

        },
      ]
    },
    {
      title: 'Reports',
      path: routes.ReportsPage,
      icon: <CustomIcon icon='png:im.im_school' />,
      items: [
        {
          title: 'Students report Card',
          path: routes.ReportCardPage,

        },
        // {
        //   title: 'Students info report',
        //   path:  routes.WalletFundingPage,

        // },
        // {
        //   title: 'Parents info report',
        //   path:  routes.WalletFundingPage,

        // },
        // {
        //   title: 'Students Monthly Attendance Report',
        //   path:  routes.WalletFundingPage,
        // },
        // {
        //   title: 'Staff Monthly Attendance Report',
        //   path:  routes.WalletFundingPage,

        // },
        // {
        //   title: 'Fee Collection Report',
        //   path:  routes.WalletFundingPage,

        // },
        // {
        //   title: 'Student Progress Report',
        //   path:  routes.WalletFundingPage,

        // },
        // {
        //   title: 'Accounts Report',
        //   path:  routes.WalletFundingPage,

        // },
      ]
    },
    // {
    //   title: 'settings',
    //   path: routes.SettingsPage,
    //   icon: <Iconify icon='eva:settings-2-outline'/>,
    // },

  ];

}
/**
* @param {Account} account 
* @param {Account} institute 
* @returns 
*/
export const getOthersNavigation = (account, institute) => [
  {
    title: 'dashboard',
    path: routes.HomePage,
    icon: icon('ic_analytics'),
  },

  {
    title: 'Accounts',
    path: routes.ReportPage,
    icon: icon('ic_user'),
    items: [
      {
        title: 'Chart Of Account',
        path: routes.ReportCardPage,

      },
      {
        title: 'Add Income',
        path: routes.ReportSmsSummaryPage,

      },
      {
        title: 'Add Expense',
        path: routes.ReportSmsSummaryPage,

      },
      {
        title: 'Account Statement',
        path: routes.ReportSmsSummaryPage,

      }
    ]
  },
  {
    title: 'Lessons',
    path: routes.LessonsPage,
    icon: icon('ic_user'),
    items: [
      {
        title: 'Lesson Plans',
        path: routes.LessonPlanAllPage,
      }
    ]
  },
  {
    title: 'Messaging',
    path: routes.MessagesPage,
    icon: <Iconify icon='eva:settings-2-outline' />,
    items: [{
      title: 'Institute Chat Room',
      path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}`)
    }]
  },
  {
    title: 'Live Class',
    path: routes.SettingsPage,
    icon: <Iconify icon='eva:settings-2-outline' />,
    items: [{
      title: 'Institute Live Class',
      path: routes.LiveClassRoomPage.replace(':room_id', `institute::${institute.id}`)
    }]
  }

]