import { useCallback, useEffect, useState } from "react";
// import { set, sub } from 'date-fns';
// import { faker } from '@faker-js/faker';
import { userHttpClient } from "src/http-clients";
import { useAccount } from "src/auth";


// const NOTIFICATIONS = [
//     {
//       id: faker.string.uuid(),
//       title: 'Your order is placed',
//       description: 'waiting for shipping',
//       avatar: null,
//       type: 'order_placed',
//       createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//       isUnRead: true,
//     },
//     {
//       id: faker.string.uuid(),
//       title: faker.person.fullName(),
//       description: 'answered to your comment on the Minimal',
//       avatar: '/assets/images/avatars/avatar_2.jpg',
//       type: 'friend_interactive',
//       createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//       isUnRead: true,
//     },
//     {
//       id: faker.string.uuid(),
//       title: 'You have new message',
//       description: '5 unread messages',
//       avatar: null,
//       type: 'chat_message',
//       createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//       isUnRead: false,
//     },
//     {
//       id: faker.string.uuid(),
//       title: 'You have new mail',
//       description: 'sent from Guido Padberg',
//       avatar: null,
//       type: 'mail',
//       createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//       isUnRead: false,
//     },
//     {
//       id: faker.string.uuid(),
//       title: 'Delivery processing',
//       description: 'Your order is being shipped',
//       avatar: null,
//       type: 'order_shipped',
//       createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//       isUnRead: false,
//     },
//   ];
export default function useNotifications (){
    const account = useAccount()
    const [notifications, setNotifications] = useState([]);
    const reload = useCallback(()=>userHttpClient.get('/api/notifications', {
            params:{
                filters:{
                    user: account.id
                },
                sort:["createdAt:desc"]
            }
        }).then(res =>  setNotifications(res.data.data.map((data)=>({
            id: data.id,
            title: data.attributes.title,
            description: data.attributes.short_description,
            avatar: null,
            type: data.attributes.type,
            createdAt: new Date(data.attributes.createdAt),
            isUnRead:  data.attributes.isUnRead!==false,
        })))),[account.id])

    const markAllAsRead = useCallback(()=>{
        userHttpClient.put(`/api/users/${account.id}/notifications/mark-all-as-read`, {}).then(res =>    setNotifications(
            (prev)=>  prev.map((notification) => ({
                ...notification,
                isUnRead: false,
              }))
            ))
    },[account.id])
    useEffect(()=>{
        let timeOutId 
        const f = ()=>{
            reload()
            .finally(()=>{  
              timeOutId = setTimeout(f, 15000)
            })
        }
        f()
        return ()=> clearTimeout(timeOutId)
    },[reload])
   
   return {notifications, reload, markAllAsRead}
}