import { useTheme } from "@emotion/react";
import { CircularProgress, Stack } from "@mui/material";
import Logo from "src/components/logo";

export default function BootIndicator() {
    const theme = useTheme();

    return (<Stack alignItems="center" justifyContent="center" sx={{height:'100vh', backgroundColor: theme.palette.primary.main, color:theme.palette.primary.contrastText }}>
    
    <Stack alignItems="center" flexDirection="column" justifyContent="center">
      <Logo variant="large" />
      <CircularProgress color='inherit' />
    </Stack>
    
  </Stack>);
}