import _, { isNumber } from "lodash";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInstituteID } from "src/auth";
import { BootstrapFns } from "src/auth/auth-context";
import { userHttpClient } from "src/http-clients";
import BootIndicator from "src/sections/indicator/bootIndicator";

export const LicensePolicies = Object.freeze({
    "CreateResultManagement": "CreateResultManagement",
    "ReadResultManagement": "ReadResultManagement",
    "UpdateResultManagement": "UpdateResultManagement",
    "DeleteResultManagement": "DeleteResultManagement",

    "CreateClass": "CreateClass",
    "ReadClass": "ReadClass",
    "UpdateClass": "UpdateClass",
    "DeleteClass": "DeleteClass",


    "CreateSubject": "CreateSubject",
    "ReadSubject": "ReadSubject",
    "UpdateSubject": "UpdateSubject",
    "DeleteSubject": "DeleteSubject",


    "CreateCommunicationBook": "CreateCommunicationBook",
    "ReadCommunicationBook": "ReadCommunicationBook",
    "UpdateCommunicationBook": "UpdateCommunicationBook",
    "DeleteCommunicationBook": "DeleteCommunicationBook",

    "CreateUserManagement": "CreateUserManagement",
    "ReadUserManagement": "ReadUserManagement",
    "UpdateUserManagement": "UpdateUserManagement",
    "DeleteUserManagement": "DeleteUserManagement",

    "CreateStudentRecord": "CreateStudentRecord",
    "ReadStudentRecord": "ReadStudentRecord",
    "UpdateStudentRecord": "UpdateStudentRecord",
    "DeleteStudentRecord": "DeleteStudentRecord",

    "CreateStaffRecord": "CreateStaffRecord",
    "ReadStaffRecord": "ReadStaffRecord",
    "UpdateStaffRecord": "UpdateStaffRecord",
    "DeleteStaffRecord": "DeleteStaffRecord",

    "ReadParentPortal": "ReadParentPortal",
    "CreateParentPortal": "CreateParentPortal",
    "UpdateParentPortal": "UpdateParentPortal",
    "DeleteParentPortal": "DeleteParentPortal",

    "ReadTeacherPortal": "ReadTeacherPortal",
    "CreateTeacherPortal": "CreateTeacherPortal",
    "UpdateTeacherPortal": "UpdateTeacherPortal",
    "DeleteTeacherPortal": "DeleteTeacherPortal",

    "CreateClassChat": "CreateClassChat",
    "ReadClassChat": "ReadClassChat",
    "UpdateClassChat": "UpdateClassChat",
    "DeleteClassChat": "DeleteClassChat",

    "CreateAttendance": "CreateAttendance",
    "ReadAttendance": "ReadAttendance",
    "UpdateAttendance": "UpdateAttendance",
    "DeleteAttendance": "DeleteAttendance",

    "CreateSchoolCalendar": "CreateSchoolCalendar",
    "ReadSchoolCalendar": "ReadSchoolCalendar",
    "UpdateSchoolCalendar": "UpdateSchoolCalendar",
    "DeleteSchoolCalendar": "DeleteSchoolCalendar",

    "CreateLessonNote": "CreateLessonNote",
    "ReadLessonNote": "ReadLessonNote",
    "UpdateLessonNote": "UpdateLessonNote",
    "DeleteLessonNote": "DeleteLessonNote",

    "CreateOnlineHomework": "CreateOnlineHomework",
    "ReadOnlineHomework": "ReadOnlineHomework",
    "UpdateOnlineHomework": "UpdateOnlineHomework",
    "DeleteOnlineHomework": "DeleteOnlineHomework",

    "CreateAccounting": "CreateAccounting",
    "ReadAccounting": "ReadAccounting",
    "UpdateAccounting": "UpdateAccounting",
    "DeleteAccounting": "DeleteAccounting",

    "CreateOnlinePaymentIntegration": "CreateOnlinePaymentIntegration",
    "ReadOnlinePaymentIntegration": "ReadOnlinePaymentIntegration",
    "UpdateOnlinePaymentIntegration": "UpdateOnlinePaymentIntegration",
    "DeleteOnlinePaymentIntegration": "DeleteOnlinePaymentIntegration"
})

export const LicenseError = Object.freeze({
    default: "License Invalid: You do not have permission to access this feature. Please check your subscription plan or contact support for assistance.",
    [LicensePolicies.CreateAccounting]: "License Invalid: You currently do not have access to the Accounting module. Please upgrade to the Standard plan to enable Accounting features, or contact support for more information.",
    [LicensePolicies.CreateAttendance]: "License Invalid: Your current plan does not allow access to the Attendance module. Please upgrade your subscription to enable attendance tracking or reach out to support for assistance.",
    for: (scope) => LicenseError[scope] ?? LicenseError.default
})

export const LicenseContext = createContext({})
const DEFAULT_LICENSE = Object.freeze({
    attributes: {
        license_no: "FREE",
        end_date: "Never",
        policies: [{
            __component: "license-policies.free-policy",
            "CreateResultManagement": true,
            "ReadResultManagement": true,
            "UpdateResultManagement": true,
            "DeleteResultManagement": true,

            "CreateClass": true,
            "ReadClass": true,
            "UpdateClass": true,
            "DeleteClass": true,

            "CreateSubject": true,
            "ReadSubject": true,
            "UpdateSubject": true,
            "DeleteSubject": true,

            "CreateCommunicationBook": true,
            "ReadCommunicationBook": true,
            "UpdateCommunicationBook": true,
            "DeleteCommunicationBook": true,

            "CreateUserManagement": true,
            "ReadUserManagement": true,
            "UpdateUserManagement": true,
            "DeleteUserManagement": true,

            "CreateStudentRecord": true,
            "ReadStudentRecord": true,
            "UpdateStudentRecord": true,
            "DeleteStudentRecord": true,

            "CreateStaffRecord": true,
            "ReadStaffRecord": true,
            "UpdateStaffRecord": true,
            "DeleteStaffRecord": true,

            "ReadParentPortal": true,
            "CreateParentPortal": false,
            "UpdateParentPortal": false,
            "DeleteParentPortal": false,

            "ReadTeacherPortal": true,
            "CreateTeacherPortal": false,
            "UpdateTeacherPortal": false,
            "DeleteTeacherPortal": false,

            "CreateClassChat": true,
            "ReadClassChat": true,
            "UpdateClassChat": true,
            "DeleteClassChat": true,

            "CreateAttendance": true,
            "ReadAttendance": true,
            "UpdateAttendance": true,
            "DeleteAttendance": false,

            "CreateSchoolCalendar": true,
            "ReadSchoolCalendar": true,
            "UpdateSchoolCalendar": true,
            "DeleteSchoolCalendar": true,

            "CreateLessonNote": true,
            "ReadLessonNote": true,
            "UpdateLessonNote": true,
            "DeleteLessonNote": true,

            "CreateOnlineHomework": true,
            "ReadOnlineHomework": true,
            "UpdateOnlineHomework": true,
            "DeleteOnlineHomework": true,

            "CreateAccounting": true,
            "ReadAccounting": true,
            "UpdateAccounting": true,
            "DeleteAccounting": true,

            "CreateOnlinePaymentIntegration": true,
            "ReadOnlinePaymentIntegration": true,
            "UpdateOnlinePaymentIntegration": true,
            "DeleteOnlinePaymentIntegration": true
        }]
    }
})

const LicenseProvider = ({ children }) => {
    const [license, setLicense] = useState()
    const [loading, setLoading] = useState(true);
    const _timer = useRef(null)
    const _abort = useRef()
    const instituteId = useInstituteID()
    const load = useCallback(() => {
        console.log("am here", instituteId)
        if(instituteId ===null || instituteId ===undefined) {
            setLoading(false);
            return null
        }
        _abort.current = new AbortController()
        return userHttpClient
            .get(`/api/users/${instituteId}/license`, {
                signal: _abort.current.signal
            })
            .then(res => {
                setLoading(false);
                setLicense(res.data.data);
                _abort.current = null
                _timer.current = null
            })
            .catch((error) => {
                if (userHttpClient.isAxiosError(error) && error?.response?.status === 400) {
                    setLoading(false);
                    setLicense(DEFAULT_LICENSE)
                    _abort.current = null
                    _timer.current = null
                }
                else {
                    setLoading(false);
                    setLicense(null)
                    _timer.current = setTimeout(3000, load);
                }
            })
    }, [instituteId]);
    useEffect(()=>{
        BootstrapFns.push(()=>load())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        load()
        return () => {
            if (_abort.current) _abort.current.abort();
            if (_timer.current) clearTimeout(_timer.current)
        }
    }, [instituteId, load])
    const value = useMemo(() => ({
        loading, license,
        allow(permission, current = true) {
            if (!Array.isArray(license?.attributes?.policies)) {
                return false;
            }
            const policy = _.get(license.attributes.policies[0], permission);
            if (policy === true) {
                return true;
            }
            if (policy === false) {
                return false;
            }
            if (isNumber(policy)) {
                return policy >= current;
            }
            return policy === current
        },
        check(permission) {
            if (!Array.isArray(license?.attributes?.policies)) {
                return false;
            }
            return _.get(license.attributes.policies[0], permission) === true
        }
    }), [loading, license]);

    if( loading){
        return (<BootIndicator/>)
    }
    return (
        <LicenseContext.Provider value={value}>
            {children}
        </LicenseContext.Provider>)
}
LicenseProvider.propTypes = {
    children: PropTypes.node
}
export default LicenseProvider

