/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { AuthorizationProvider } from 'src/auth/authorization-provider';
import { SnackBarProvider } from './components/snackbar/snackbar-provider';
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

  
    <ThemeProvider>
      <SnackBarProvider>
      <AuthorizationProvider>
        <Router />
      </AuthorizationProvider>
      </SnackBarProvider>
     
    </ThemeProvider>
    </LocalizationProvider>
    
  );
}
