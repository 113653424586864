import _ from "lodash"
import { userHttpClient } from "src/http-clients"
import { Result } from "src/utils/result"

///-----------------------------------------------------------------------
export const instituteSearchFilterBuilder = (by, search) => ({
    isDeleted: false,
    '$or': [
        {

            [by]: by === 'class' ? undefined : {
                "username": {
                    '$containsi': search
                },
              
            }
        },
        {
            [`${by}_no`]: {
                '$containsi': search
            },
        },
        by === 'class' ? {
            'name':
            {
                '$containsi': search
            },
        } : {
            [`${by}`]: {
                "meta": {
                    'name': `${_.capitalize(by)} Name`,
                    'value':
                    {
                        '$containsi': search
                    }
                },
            }
        },
    ]
})

//  --------------------------------------------------------
export const instituteTeacherSearchFilterBuilder = (search) => ({
    isDeleted: false,
    '$or': [
        {
            role: 'teacher',
            employee: {
                "username": {
                    '$containsi': search
                }
            }
        },
        {
            role: 'teacher',
            [`employee_no`]: {
                '$containsi': search
            }
        },
        {
            role: 'teacher',
            employee: {
                "meta": {
                    'name': `Employee Name`,
                    'value':
                    {
                        '$containsi': search
                    }
                }
            }
        },
    ]
})

export const instituteTeacherSearch = async (instituteId, query = {}) => instituteEmployeeSearch(instituteId, { ..._.set(query, 'filters.role', 'teacher') })

//  --------------------------------------------------------

export const instituteClassSearchFilterBuilder = (search) => instituteSearchFilterBuilder('class', search)

export const instituteClassSearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/classes/search`, {
            params: query

        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


//  --------------------------------------------------------



export const instituteChartOfAccountSearchFilterBuilder =  ( search) => ({
    '$or': [
        {
           
            [`name`]: {
                '$containsi': search
            },
          
        },
        {
            [`type`]: {
                '$startsWith': search
            },
        }
    ]
})
export const instituteChartOfAccountSearch = async (instituteId, query = {}) => {
    const params = query??{}
   _.set( params, 'filters.institute',instituteId)
    try {
        const result = await userHttpClient.get(`/api/chart-of-accounts`, {params})
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


//  --------------------------------------------------------

export const instituteEmployeeSearchFilterBuilder = (search) => instituteSearchFilterBuilder('employee', search)

export const instituteEmployeeSearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/employees/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const instituteClassSubjectSearchFilterBuilder = (search) => ({
    isDeleted: false,
    '$or': [
        {   
            "name": {
                '$containsi': search
            },
        },
        {
            class_subject_no: {
                '$startsWith': search
            },
        },
    ]
})
export const instituteClassSubjectSearch = async (instituteId,classId,  query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/classes/${classId}/subjects`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const instituteStudentSearchFilterBuilder = (search) => instituteSearchFilterBuilder('student', search)

export const instituteStudentSearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/students/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const instituteParentSearchFilterBuilder = (search) => instituteSearchFilterBuilder('parent', search)

export const instituteParentSearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/parents/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------


//  --------------------------------------------------------

export const instituteFamilySearchFilterBuilder = (search) => ({
    '$or': [
        {
           
            [`family_name`]: {
                '$containsi': search
            }
        },
        {
            [`family_no`]: {
                '$startsWith': search
            }
        }
    ]
})
export const instituteFamilySearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/families/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const instituteAssessmentSearchFilterBuilder = (search) => ({
    '$or': [
        {
           
            [`title`]: {
                '$containsi': search
            }
        },
        {
           
            [`description`]: {
                '$containsi': search
            }
        },
        {
            [`assessment_no`]: {
                '$startsWith': search
            }
        }
    ]
})
export const instituteAssessmentSearch = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/assessments/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const instituteStudentParticularFees = async (instituteId, studentId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/students/${studentId}/particular-fees`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const instituteClassParticularFees = async (instituteId, classId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/classes/${classId}/particular-fees`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const instituteParticularFees = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/particular-fees`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


//  --------------------------------------------------------



export const updateInstituteStudentParticularFees = async (instituteId, studentId, params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/institutes/${instituteId}/students/${studentId}/particular-fees`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const updateInstituteClassParticularFees = async (instituteId, classId, params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/institutes/${instituteId}/classes/${classId}/particular-fees`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const updateInstituteParticularFees = async (instituteId, params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/institutes/${instituteId}/particular-fees`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


//  --------------------------------------------------------

/// Mark gradings


export const instituteMarkGradings = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/mark-gradings`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}





export const updateInstituteMarkGradings = async (instituteId, params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/institutes/${instituteId}/mark-gradings`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------
// Details Fee Challan


export const addInstituteDetailsFeeChallan = async (instituteId, params, query = {}) => {
    try {
        const result = await userHttpClient.post(`/api/institutes/${instituteId}/challans`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const getInstituteClass = async (instituteId, query = {}) => {
    try {
        const result = await userHttpClient.get(`/api/institutes/${instituteId}/classes/search`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data, "Fetch institute class successfully"))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const addInstituteClass = async (instituteId, params, query = {}) => {
    try {
        const result = await userHttpClient.post(`/api/institutes/${instituteId}/classes`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const updateInstituteClass = async (instituteId,classId, params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/institutes/${instituteId}/classes/${classId}`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------

export const addClassSubject = async ( params, query = {}) => {
    try {
        const result = await userHttpClient.post(`/api/class-subjects`, params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const removeClassSubject = async (id, query = {}) => {
    try {
        const result = await userHttpClient.delete(`/api/class-subjects/${id}`, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const updateClassSubject = async (id,params, query = {}) => {
    try {
        const result = await userHttpClient.put(`/api/class-subjects/${id}`,params, {
            params: query
        })
        return Result.ok(result.data, Result.getMessage(result.data))
    } catch (error) {
        if (userHttpClient.isAxiosError(error)) {
            return Result.error(error?.response?.data, Result.getMessage(error?.response?.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

//  --------------------------------------------------------