import _ from "lodash";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";
import { useInstituteID } from "src/auth";
import { instituteMarkGradings } from "src/services/institute";

export const GradingContext = createContext({})
const DEFAULT_GRADINGS=(label)=>[{
    
}]
const DEFAULT_LEVEL=(label)=>[ {
    "grade": 'A',
    "value": 5,
    "status": "PASS",
    description: `Excellent show of ${label}`
},
{
    "grade": 'B',
    "value": 4,
    "status": "PASS",
    description: `High level show of ${label}`
},
{
    "grade": 'C',
    "value": 3,
    "status": "PASS",
    description: `Fair exhibition of ${label}`
},
{
    "grade": 'D',
    "value": 2,
    "status": "PASS",
    description: `Low exhibition of ${label}`
},
{
    "grade": 'E',
    "value": 1,
    "status": "FAIL",
    description: `No regard for the ${label}`
}]
const GradingProvider = ({ children }) => {
    const [marks, setMarks] = useState({});
    const [traits, setTraits] = useState([]);
    const [skills, setSkills] = useState([]);
    const [gradings, setGradings] = useState([]);
    const [loading, setLoading] = useState(true);

    const instituteId = useInstituteID()
    useEffect(() => {
        let active = true;
        instituteMarkGradings(instituteId, {
            populate: ['gradings','skills', 'traits']
        }).then((result) => {
            if (active && result.ok) {
                setGradings(result.data.attributes.gradings ?? DEFAULT_GRADINGS())
                setTraits(result.data.attributes.traits ?? DEFAULT_LEVEL('trait'))
                setSkills(result.data.attributes.skills ?? DEFAULT_LEVEL('skill'))
                const newMarks = _.pick(result.data.attributes, ['no_of_subject_failure_criteria', 'subject_failure_criteria', 'overall_failure_criteria'])
                console.log("newMarks", newMarks)
                setMarks(newMarks)
                setLoading(false);
            }
        }).catch(() => {
            if (active) {
                setLoading(false);
                setMarks({})
                setGradings([])
                setTraits([])
            }
        })

        return () => {
            active = false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instituteId])
    const value = useMemo(() => ({
        marks,
        gradings,
        loading,
        traits,
        skills,
        set: (data) => {
          if(data?.traits){
            setTraits(data?.traits)
          }
          if(data?.skills){
            setSkills(data?.skills)
          }
          if(data?.marks){
            setMarks(data?.marks)
          }
          if(data?.gradings){
            setGradings(data?.gradings)
          }
        },
         getScoreMark:(score)=> {
            console.log("score", score, "gradings", gradings)

            // eslint-disable-next-line no-plusplus
            for(let i=0; i< gradings.length; i++){
                const grading = gradings[i]
               if(score >= grading.from && score <= grading.up_to ){
                return {grade:grading.grade, status:grading.status}
               }
            } 
            return {grade: "UNKNOWN", status:"UNKNOWN"}
        },
        getScoreTrait:(score) => {
            // eslint-disable-next-line no-plusplus
            for(let i=0; i< traits.length; i++){
                const trait = traits[i]
               if(Number(score) === trait.value){
                return {
                    grade:trait.grade, 
                    status:trait.status,
                    description: trait.description
                }
               }
            } 
            return {grade: "UNKNOWN", status:"UNKNOWN",  description:"UNKNOWN"}
        },
        getScoreSkill:(score) => {
            // eslint-disable-next-line no-plusplus
            for(let i=0; i< skills.length; i++){
                const skill = skills[i]
               if(Number(score) === skill.value){
                return {
                    grade:skill.grade, 
                    status:skill.status,
                    description: skill.description
                }
               }
            } 
            return {grade: "UNKNOWN", status:"UNKNOWN",  description:"UNKNOWN"}
        }
    }), [gradings, loading, skills,traits, marks])
    return (<GradingContext.Provider value={value}>
        {children}
    </GradingContext.Provider>)
}
GradingProvider.propTypes = {
    children: PropTypes.node
}
export default GradingProvider

