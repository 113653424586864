import { LicensePolicies } from "src/contexts/LicenseContext"
import { getAccountNav, getAssessmentNav, getAttendanceNav, getClassNav, getDashbordNav, getEmployeeNav, getExamNav, getGeneralSettingsNav, getLessonNav, getLiveClassNav, getMessagingNav, getParentNav, getReportNav, getStudentNav, getSubjectNav, getTimetableNav } from "./navigations"

/**
 * 
 * @param {Account} institute 
 * @returns 
 */
export const getInstituteNavigation = (institute, allow) => {
    // dddddd
    /**
     * 
     */


    const navigation = [
        getDashbordNav(),
        getGeneralSettingsNav(),
    ];

    navigation.push({
        ...getAttendanceNav(),
        disabled: !allow(LicensePolicies.ReadAttendance)
    },
        {
            ...getClassNav(),
            disabled: !allow(LicensePolicies.ReadClass),
        },
        {
            ...getSubjectNav(),
            disabled: !allow(LicensePolicies.ReadSubject)
        },
        {
            ...getLessonNav(),
            disabled: !allow(LicensePolicies.ReadLessonNote)
        },
        {
            ...getStudentNav(),
            disabled: !allow(LicensePolicies.ReadStudentRecord)
        },
        {
            ...getParentNav(),
            disabled: !allow(LicensePolicies.ReadParentPortal)
        },

        {
            ...getEmployeeNav(),
            disabled: !allow(LicensePolicies.ReadUserManagement)
        },
        {
            ...getAccountNav(),
            disabled: !allow(LicensePolicies.ReadAccounting)
        },
        {
            ...getAssessmentNav(),
            disabled: !allow(LicensePolicies.ReadOnlineHomework)
        },
        {
            ...getExamNav(),
            disabled: !allow(LicensePolicies.ReadOnlineHomework)
        },
        
    )



    navigation.push(
        { ...getMessagingNav({ institute }), disabled: !allow(LicensePolicies.ReadClassChat) },
        { ...getLiveClassNav({ institute }), disabled: !allow(LicensePolicies.ReadClassChat) },
    )

    navigation.push(
        { ...getTimetableNav(), disabled: !allow(LicensePolicies.ReadSchoolCalendar) }
    )



    navigation.push(

        { ...getReportNav(), disabled: !allow(LicensePolicies.ReadResultManagement) }
    )

    return navigation


}

