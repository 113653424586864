import _ from 'lodash'
import {ensureBool} from './ensure-bool'
import {ensureString} from './ensure-string'

export class Result {

  #ok

  #message

  #result

  constructor ({ ok, message, result }) {
    message = message?.toString()
    ensureBool(ok)
    ensureString(message)

    this.#ok = ok
    this.#message = message
    this.#result = result
  }

  /**
   *
   * @param {any} result
   * @param {string?} message
   */
  static ok (result, message = 'OK') {
    return new this({
      ok: true,
      result,
      message
    })
  }

  /**
   *
   * @param {any} result
   * @param {string?} message
   */
  static error(result, message= 'ERROR') {
    return new this({
      ok: false,
      result,
      message
    })
  }

  /**
   * @return {boolean}
   */
  get notOk () {
    return !this.ok
  }

  /**
   * @return {boolean}
   */
  get ok () {
    return this.#ok
  }

  /**
     * @return {string?}
     */
  get message () {
    return this.#message
  }

  /**
     * @return {any}
     */
  get result () {
    return this.#result
  }

 /**
     * @return {any}
     */
 get data () {
  return this.#result?.data ?? {}
}



 /**
     * @return {any}
     */
 get error () {
  return this.#result?.error ?? {}
}

/**
     * @return {any}
     */
get meta () {
  return this.#result?.meta ?? {}
}

 /**
  * @return {any}
  */
 get (path, defaultValue=undefined) {
  return _.get(this.#result, path)?? defaultValue
}

/**
 * @return {boolean}
 */
has (path) {
  return !!this.get(path)
}

  /**
   *
   * @param {any} result
   * @param {string?} fallbackMessage
   * @returns {string?}
   */
  static getMessage (result, fallbackMessage) {
    if (result == null) return fallbackMessage
    if (typeof result !== 'object') return fallbackMessage
    if (Array.isArray (result?.data?.error?.details?.errors)) {
      return  result?.data?.error?.details?.errors[0]?.message ?? fallbackMessage
    }
    if (Array.isArray (result?.error?.details?.errors)) {
      return  result?.error?.details?.errors[0]?.message ?? fallbackMessage
    }
    if (typeof (result)?.data?.error?.message === 'string') {
      return (result).data?.error.message
    }
    if (typeof (result)?.data?.message === 'string') {
      return (result).data.message
    }
    if (typeof (result)?.error?.message === 'string') {
      return (result).error.message
    }
   
    if (typeof (result)?.message === 'string') {
      return (result).message
    }
    return fallbackMessage
  }
}
