import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { LoginGuard } from 'src/auth/guards';
import DashboardLayout from 'src/layouts/dashboard';
import ExaminationLayout from 'src/layouts/examination';

import TestLayout from 'src/layouts/test';
import AssessmentLayout from 'src/layouts/assessment';
import GradingProvider from 'src/contexts/GradingContext';
import LicenseProvider from 'src/contexts/LicenseContext';
import routes from '../utils/routes'

export const IndexPage = lazy(() => import('src/pages/app'));
export const BlogPage = lazy(() => import('src/pages/blog'));
export const UserPage = lazy(() => import('src/pages/user'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const ForgotPasswordPage = lazy(() => import('src/pages/forgot-password'));
export const ResetPasswordPage = lazy(() => import('src/pages/reset-password'));
export const RegisterPage = lazy(() => import('src/pages/register'));
export const ChatRoomPage = lazy(() => import('src/pages/chat/chat'));
export const LiveClassRoomPage = lazy(() => import('src/pages/chat/live-class'));
export const DirectMessagingPage = lazy(() => import('src/pages/chat/dm'));
export const MeetingPage = lazy(() => import('src/pages/chat/meeting'));

export const InstituteProfilePage = lazy(() => import('src/pages/institute/institute-profile'));
export const InstituteFeeParticularsPage = lazy(() => import('src/pages/institute/institute-fee-particulars'));
export const InstituteDetailsFeeChallanPage = lazy(() => import('src/pages/institute/institute-details-fee-challan'));
export const InstituteRulesAndRegulations = lazy(() => import('src/pages/institute/institute-rules-and-regulations'));
export const InstituteAccountSettingsPage = lazy(() => import('src/pages/institute/institute-account-settings'));
export const InstituteAcademicSettingsPage = lazy(() => import('src/pages/institute/institute-academic-settings'));
export const InstituteMarksGradingPage = lazy(() => import('src/pages/institute/institute-marks-grading'));

/// ---------------------------------------------------------
export const EmployeeAllPage = lazy(() => import('src/pages/employee/employee-all'));
export const EmployeeAddNewPage = lazy(() => import('src/pages/employee/employee-add-new'));
export const EmployeeJobLetterPage = lazy(() => import('src/pages/employee/employee-job-letter'));

export const EmployeeStaffIdCardsPage = lazy(() => import('src/pages/employee/employee-staff-idcards'));
export const EmployeePage = lazy(() => import('src/pages/employee/employee'));

/// ---------------------------------------------------------

export const StudentAllPage = lazy(() => import('src/pages/student/student-all'));
export const StudentAddNewPage = lazy(() => import('src/pages/student/student-add-new'));
export const StudentAdmissionLetterPage = lazy(() => import('src/pages/student/student-admission-letter'));
export const StudentPromotionPage= lazy(() => import('src/pages/student/student-promotion'));
export const StudentIdCardPage = lazy(() => import('src/pages/student/student-idcard'));
export const StudentPage = lazy(() => import('src/pages/student/student'));

// -----------------------------------------

export const LessonPlanAllPage = lazy(() => import('src/pages/lesson/lesson-plan-all'));
export const LessonPlanAddNewPage = lazy(() => import('src/pages/lesson/lesson-plan-add-new'));
export const LessonPlanEditNewPage = lazy(() => import('src/pages/lesson/lesson-plan-edit'));
// export const StudentAdmissionLetterPage = lazy(() => import('src/pages/student/student-admission-letter'));
// export const StudentPromotionPage= lazy(() => import('src/pages/student/student-promotion'));
// export const StudentIdCardPage = lazy(() => import('src/pages/student/student-idcard'));
export const LessonPlanPage = lazy(() => import('src/pages/lesson/lesson-plan'));

// -----------------------------------------


export const AssignClassToSubjectsPage = lazy(() => import('src/pages/subject/assign-class-to-subjects'));
export const ClassWithSubjectsPage = lazy(() => import('src/pages/subject/class-with-subjects'));

export const ClassAllPage = lazy(() => import('src/pages/class/class-all'));
export const ClassAddNewPage = lazy(() => import('src/pages/class/class-add-new'));
export const ClassPage = lazy(() => import('src/pages/class/class'));
export const ClassEditPage = lazy(() => import('src/pages/class/class-edit'));


export const ProfilePage = lazy(() => import('src/pages/profile'));
export const ProfileSettingsPage = lazy(() => import('src/pages/profile-settings'));
export const ProfileEditPage = lazy(() => import('src/pages/profile-edit'));
export const ProfileChangePasswordPage = lazy(() => import('src/pages/profile-change-password'));
export const WalletFundingPage = lazy(() => import('src/pages/wallet-funding'));

export const SettingsPage = lazy(() => import('src/pages/settings'));
export const SenderIDPage = lazy(() => import('src/pages/sender-id'));

export const AddressBookAddGroupPage = lazy(() => import('src/pages/address-book/add-address-book-group'));
export const AddressBookEditGroupPage = lazy(() => import('src/pages/address-book/list-address-book-group'));




export const SendSMSPage = lazy(() => import('src/pages/send-sms'));
export const SendPersonalisedSMSPage = lazy(() => import('src/pages/send-personalised-sms'));

export const ReportSmsSummaryPage = lazy(() => import('src/pages/report/report-sms-summary'));
export const ReportPaymentHistoryPage = lazy(() => import('src/pages/report/report-payment-history'));
export const ReportWalletHistoryPage = lazy(() => import('src/pages/report/report-wallet-history'));
export const ReportScheduledReportPage = lazy(() => import('src/pages/report/report-scheduled-report'));

export const ActivateAccountPage = lazy(() => import('src/pages/activate-account'));
export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));



export const ExaminationPortalPage = lazy(() => import('src/pages/examination/examination-portal'));

export const ExamAllPage = lazy(() => import('src/pages/examination/exam-all'));
export const ExamAddNewPage = lazy(() => import('src/pages/examination/exam-add-new'));
export const ExamPage = lazy(() => import('src/pages/examination/exam'));
export const ExamEditPage = lazy(() => import('src/pages/examination/exam-edit'));

export const ExamGroupAllPage = lazy(() => import('src/pages/examination/exam-group-all'));
export const ExamGroupAddNewPage = lazy(() => import('src/pages/examination/exam-group-add-new'));
export const ExamGroupEditPage = lazy(() => import('src/pages/examination/exam-group-edit'));

export const AssignExamToGroupPage = lazy(() => import('src/pages/examination/assign-exam-to-group'));


export const ManageFamiliesPage = lazy(() => import('src/pages/family/manage-families'));
// ---------------------



export const TestPortalPage = lazy(() => import('src/pages/test/test-portal'));

export const TestAllPage = lazy(() => import('src/pages/test/test-all'));
export const TestAddNewPage = lazy(() => import('src/pages/test/test-add-new'));
export const TestPage = lazy(() => import('src/pages/test/test'));
export const TestEditPage = lazy(() => import('src/pages/test/test-edit'));

export const TestGroupAllPage = lazy(() => import('src/pages/test/test-group-all'));
export const TestGroupAddNewPage = lazy(() => import('src/pages/test/test-group-add-new'));
export const TestGroupEditPage = lazy(() => import('src/pages/test/test-group-edit'));

export const AssignTestToGroupPage = lazy(() => import('src/pages/test/assign-test-to-group'));


// ----------------------------------------------------------------------



export const AssessmentPortalPage = lazy(() => import('src/pages/assessment/assessment-portal'));

export const AssessmentAllPage = lazy(() => import('src/pages/assessment/assessment-all'));
export const AssessmentAddNewPage = lazy(() => import('src/pages/assessment/assessment-add-new'));
export const AssessmentPage = lazy(() => import('src/pages/assessment/assessment'));
export const AssessmentEditPage = lazy(() => import('src/pages/assessment/assessment-edit'));

export const AssessmentGroupAllPage = lazy(() => import('src/pages/assessment/assessment-group-all'));
export const AssessmentGroupAddNewPage = lazy(() => import('src/pages/assessment/assessment-group-add-new'));
export const AssessmentGroupEditPage = lazy(() => import('src/pages/assessment/assessment-group-edit'));

export const AssignAssessmentToGroupPage = lazy(() => import('src/pages/assessment/assign-assessment-to-group'));


// ----------------------------------------------------------------------


export const ClassTimetablePage = lazy(() => import('src/pages/timetable/class-timetable'));
export const TeacherTimetablePage = lazy(() => import('src/pages/timetable/teacher-timetable'));
export const ClassRoomsTimetablePage = lazy(() => import('src/pages/timetable/classroom-timetable'));
export const CreateTimetablePage = lazy(() => import('src/pages/timetable/create-timetable'));
export const TimeperiodTimetablePage = lazy(() => import('src/pages/timetable/timeperiod-timetable'));
export const WeekdaysTimetablePage = lazy(() => import('src/pages/timetable/weekdays-timetable'));
export const ActiveClassTimetablePage = lazy(() => import('src/pages/timetable/active-class-timetable'));
// ---
const MarkEmployeesAttendancePage = lazy(() => import('src/pages/attendance/mark-employee-attendance'));
const MarkStudentsAttendancePage= lazy(() => import('src/pages/attendance/mark-student-attendance'));
const StudentsAttendanceReportPage= lazy(() => import('src/pages/attendance/students-report'));
const EmployeesAttendanceReportPage= lazy(() => import('src/pages/attendance/employees-report'));
const ClasswiseReportPage = lazy(() => import('src/pages/attendance/classwise-report'));

// ----------------------------------------------------------------------

/// ---------------------------------------------------------
export const ParentAllPage = lazy(() => import('src/pages/parent/parent-all'));
export const ParentAddNewPage = lazy(() => import('src/pages/parent/parent-add-new'));

export const ParentIdCardsPage = lazy(() => import('src/pages/parent/parent-idcards'));
export const ParentPage = lazy(() => import('src/pages/parent/parent'));

/// ---------------------------------------------------------


/// ---------------------------------------------------------
export const HomeworkAllPage = lazy(() => import('src/pages/homework/homework-all'));
export const HomeworkAddNewPage = lazy(() => import('src/pages/homework/create-homework'));
export const HomeworkMarkPage = lazy(() => import('src/pages/homework/mark-homework'));

export const HomeworkSubmitPage = lazy(() => import('src/pages/homework/submit-homework'));
export const HomeworkPage = lazy(() => import('src/pages/homework/homework'));

/// ---------------------------------------------------------

export const ChartOfAccountAllPage  = lazy(() => import('src/pages/account/chart-of-account-all'));
export const AddIncomePage  = lazy(() => import('src/pages/account/add-income'));
export const AddExpensePage  = lazy(() => import('src/pages/account/add-expense'));

export const AccountStatementPage = lazy(() => import('src/pages/account/account-statement'));
/// --------------------------------------------------

export const ReportCardPage = lazy(() => import('src/pages/report/report-card'));
/// --------------------------------------------------
export default function Router() {
  const Routes = useRoutes([
    {
      element: (
        <LicenseProvider>
        <LoginGuard>
        
          <DashboardLayout>
           
            <GradingProvider>
            <Suspense>
              <Outlet />
            </Suspense>
            </GradingProvider>
           
          </DashboardLayout>
        </LoginGuard>
        </LicenseProvider>
      ),
      children: [
        { element: <IndexPage />, index: true },
        /**
         * Institute
         */
        { path: routes.InstitutionProfilePage, element: <InstituteProfilePage /> },
        { path: routes.InstitutionFeeParticularsPage, element: <InstituteFeeParticularsPage /> },
        { path: routes.InstitutionRulesAndRegulationsPage, element: <InstituteRulesAndRegulations /> },
        { path: routes.InstitutionDetailsForFeeChallanPage, element: <InstituteDetailsFeeChallanPage /> },
        { path: routes.InstitutionAccountSettingsPage, element: <InstituteAccountSettingsPage /> },
        { path: routes.InstitutionAcademicSettingsPage, element: <InstituteAcademicSettingsPage /> },
        { path: routes.InstitutionMarksGradingPage, element: <InstituteMarksGradingPage /> },


        { path: routes.StudentAdmissionLetterPage, element: <StudentAdmissionLetterPage /> },
        { path: routes.StudentIdCardPage, element: <StudentIdCardPage /> },
        { path: routes.StudentAddNewPage, element: <StudentAddNewPage /> },
        { path: routes.StudentAllPage, element: <StudentAllPage /> },
        { path: routes.StudentPage, element: <StudentPage /> },
        { path: routes.StudentPromotionPage, element: <StudentPromotionPage /> },
        
        { path: routes.EmployeeJobLetterPage, element: <EmployeeJobLetterPage /> },
        { path: routes.EmployeeStaffIdCardsPage, element: <EmployeeStaffIdCardsPage /> },
        { path: routes.EmployeeAddNewPage, element: <EmployeeAddNewPage /> },
        { path: routes.EmployeeAllPage, element: <EmployeeAllPage /> },
        { path: routes.EmployeePage, element: <EmployeePage /> },



        { path: routes.LessonPlanAddNewPage, element: <LessonPlanAddNewPage /> },
        { path: routes.LessonPlanAllPage, element: <LessonPlanAllPage /> },
        { path: routes.LessonPlanEditPage, element: <LessonPlanEditNewPage /> },
        { path: routes.LessonPlanPage, element: <LessonPlanPage /> },
        // { path: routes.EmployeePage, element: <EmployeePage /> },



        { path: routes.ClassEditPage, element: <ClassEditPage /> },
        { path: routes.ClassAddNewPage, element: <ClassAddNewPage /> },
        { path: routes.ClassAllPage, element: <ClassAllPage /> },
        { path: routes.ClassPage, element: <ClassPage /> },

        { path: routes.AssignClassToSubjectsPage, element: <AssignClassToSubjectsPage /> },
        { path: routes.ClassWithSubjectsPage, element: <ClassWithSubjectsPage /> },




        { path: routes.ExamEditPage, element: <ExamEditPage /> },
        { path: routes.ExamAddNewPage, element: <ExamAddNewPage /> },
        { path: routes.ExamAllPage, element: <ExamAllPage /> },
        { path: routes.ExamPage, element: <ExamPage /> },
        { path: routes.ExamGroupEditPage, element: <ExamGroupEditPage /> },
        { path: routes.ExamGroupAddNewPage, element: <ExamGroupAddNewPage /> },
        { path: routes.ExamGroupAllPage, element: <ExamGroupAllPage /> },
        { path: routes.AssignExamToGroupPage, element: <AssignExamToGroupPage /> },




        { path: routes.TestEditPage, element: <TestEditPage /> },
        { path: routes.TestAddNewPage, element: <TestAddNewPage /> },
        { path: routes.TestAllPage, element: <TestAllPage /> },
        { path: routes.TestPage, element: <TestPage /> },
        { path: routes.TestGroupEditPage, element: <TestGroupEditPage /> },
        { path: routes.TestGroupAddNewPage, element: <TestGroupAddNewPage /> },
        { path: routes.TestGroupAllPage, element: <TestGroupAllPage /> },
        { path: routes.AssignTestToGroupPage, element: <AssignTestToGroupPage /> },

        { path: routes.LiveClassRoomPage, element: <LiveClassRoomPage/> },
        { path: routes.DirectMessagingPage, element: <DirectMessagingPage/> },
        
        { path: routes.ChatRoomPage, element: <ChatRoomPage/> },

        { path: routes.MeetingPage, element: <MeetingPage/> },



        
        { path: routes.ProfilePage, element: <ProfilePage /> },
        { path: routes.ProfileSettingsPage, element: <ProfileSettingsPage /> },
        { path: routes.ProfileEditPage, element: <ProfileEditPage /> },
        { path: routes.ProfileChangePasswordPage, element: <ProfileChangePasswordPage /> },
        { path: routes.WalletFundingPage, element: <WalletFundingPage /> },
        { path: routes.ReportCardPage, element: <ReportCardPage /> },
        { path: routes.ReportSmsSummaryPage, element: <ReportSmsSummaryPage /> },
        { path: routes.ReportScheduledReportPage, element: <ReportScheduledReportPage /> },
        { path: routes.ReportWalletHistoryPage, element: <ReportWalletHistoryPage /> },
        { path: routes.ReportPaymentHistoryPage, element: <ReportPaymentHistoryPage /> },
        { path: routes.SettingsPage, element: <SettingsPage /> },
        { path: routes.SenderIDPage, element: <SenderIDPage /> },
        { path: routes.AddressBookAddGroupPage, element: <AddressBookAddGroupPage /> },
        { path: routes.AddressBookListGroupPage, element: <AddressBookEditGroupPage /> },
        { path: routes.SMSSendPersonalisedPage, element: <SendPersonalisedSMSPage /> },
        { path: routes.SMSSendPage, element: <SendSMSPage /> },


        { path: routes.CreateTimetablePage, element: <CreateTimetablePage /> },
        { path: routes.TeacherTimetablePage, element: <TeacherTimetablePage /> },
        { path: routes.TimePeriodTimetablePage, element: <TimeperiodTimetablePage /> },
        { path: routes.WeekdaysTimetablePage, element: <WeekdaysTimetablePage /> },
        { path: routes.ClassRoomsTimetablePage, element: <ClassRoomsTimetablePage /> },
        { path: routes.ClassTimetablePage, element: <ClassTimetablePage /> },
        { path: routes.ActiveClassTimetablePage, element: <ActiveClassTimetablePage /> },
        


        { path: routes.MarkEmployeesAttendancePage, element: <MarkEmployeesAttendancePage /> },
        { path: routes.MarkStudentsAttendancePage, element: <MarkStudentsAttendancePage /> },
        { path: routes.StudentsAttendanceReportPage, element: <StudentsAttendanceReportPage /> },
        { path: routes.EmployeesAttendanceReportPage, element: <EmployeesAttendanceReportPage /> },
        { path: routes.ClasswiseReportPage, element: <ClasswiseReportPage /> },



        { path: routes.ParentAllPage, element: <ParentAllPage /> },
        { path: routes.ParentAddNewPage, element: <ParentAddNewPage /> },
        { path: routes.ParentIdCardsPage, element: <ParentIdCardsPage /> },
        { path: routes.ParentPage, element: <ParentPage /> },


        { path: routes.HomeworkAllPage, element: <HomeworkAllPage /> },
        { path: routes.HomeworAddNewPage, element: <HomeworkAddNewPage /> },
        { path: routes.HomeworkMarkPage, element: <HomeworkMarkPage /> },
        { path: routes.HomeworkSubmitPage, element: <HomeworkSubmitPage /> },
        { path: routes.HomeworkPage, element: <HomeworkPage /> },
        { path: routes.ManageFamiliesPage, element: <ManageFamiliesPage /> },





        { path: routes.AssessmentEditPage, element: <AssessmentEditPage /> },
        { path: routes.AssessmentAddNewPage, element: <AssessmentAddNewPage /> },
        { path: routes.AssessmentAllPage, element: <AssessmentAllPage /> },
        { path: routes.AssessmentPage, element: <AssessmentPage /> },
        { path: routes.AssessmentGroupEditPage, element: <AssessmentGroupEditPage /> },
        { path: routes.AssessmentGroupAddNewPage, element: <AssessmentGroupAddNewPage /> },
        { path: routes.AssessmentGroupAllPage, element: <AssessmentGroupAllPage /> },
        { path: routes.AssignAssessmentToGroupPage, element: <AssignAssessmentToGroupPage /> },


        { path: routes.ChartOfAccountAllPage, element: <ChartOfAccountAllPage /> },
        { path: routes.AddIncomePage, element: <AddIncomePage /> },
        { path: routes.AddExpensePage, element: <AddExpensePage /> },
        { path: routes.AccountStatementPage,   element: <AccountStatementPage/>},
        {path: `${routes.ReportCardPage}/*`, element:<ReportCardPage/> , }
      ],
    },
    {
      element: (
        <ExaminationLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </ExaminationLayout>
      ),
      children: [
        { element: <ExaminationPortalPage />, path: routes.ExaminationPortalPage, },
      ]
    },
    {
      element: (
        <AssessmentLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AssessmentLayout>
      ),
      children: [
        { element: <AssessmentPortalPage />, path: routes.AssessmentPortalPage, },
      ]
    },
    {
      element: (
        <TestLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </TestLayout>
      ),
      children: [
        { element: <TestPortalPage />, path: routes.TestPortalPage, },
      ]
    },
    {
      path: routes.ActivateAccountPage,
      element: <ActivateAccountPage />,
      
    },
    {
      path: routes.LoginPage,
      element: <LoginPage />,
    },

    {
      path: routes.ForgotPasswordPage,
      element: <ForgotPasswordPage />,
    },
    {
      path: routes.ResetPasswordPage,
      element: <ResetPasswordPage />,
    },
    {
      path: routes.RegisterPage,
      element: <RegisterPage />,
    },
    {
      path: routes.NotFoundPage,
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return Routes;
}
