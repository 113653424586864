import EventEmitter from 'events';
import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'

PouchDB.plugin(PouchDBFind);
export const AuthDB = new PouchDB('auth');
export const config = Object.freeze({
    appName: 'Rosmon SMS',
    currencySymbol: "₦",
    appBaseURL:  import.meta.env.VITE_app_BaseURL  ?? 'https://rosmon-sms.flux.i.ng',
    apiBaseURL:  import.meta.env.VITE_api_BaseURL  ?? 'https://admin.rosmon-sms.flux.i.ng'
})

export const EMPLOYEES_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/employees')
export const EMPLOYEES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/employees/count')

export const PARENTS_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/parents')
export const PARENTS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/parents/count')


export const REPORT_CARDS_ENDPOINT = config.apiBaseURL.concat('/api/report-cards')
export const REPORT_CARDS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/report-cards/count')

export const USER_META_VALUE_ENDPOINT = config.apiBaseURL.concat('/api/users/:userId/metas/:key/value')
export const USER_META_VALUES_ENDPOINT = config.apiBaseURL.concat('/api/users/:userId/metas/:key/values')

export const INSITUTE_CHALLANS_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/challans')
export const INSITUTE_CHALLANS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/challans/count')



export const INSITUTE_ACTIVITIES_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/activities')
export const INSITUTE_ACTIVITIES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/activities/count')



export const INSITUTE_USER_ACTIVITIES_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/users/:userId/activities')
export const INSITUTE_USER_ACTIVITIES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/users/:userId/activities/count')

export const INSITUTE_CHART_OF_ACCOUNTS_ENDPOINT = config.apiBaseURL.concat('/api/chart-of-accounts')
export const INSITUTE_CHART_OF_ACCOUNTS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/chart-of-accounts/count')

export const INSITUTE_ACCOUNT_TRANSACTIONS_ENDPOINT = config.apiBaseURL.concat('/api/account-transactions')
export const INSITUTE_ACCOUNT_TRANSACTIONS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/account-transactions/count')

export const INSITUTE_CLASSES_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/classes')
export const INSITUTE_CLASS_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/classes/:classId')

export const INSITUTE_CLASSES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/classes/count')


export const INSITUTE_STUDENTS_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/students')
export const INSITUTE_STUDENTS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/students/count')


export const INSITUTE_EXAMS_ENDPOINT = config.apiBaseURL.concat('/api/exams')
export const INSITUTE_EXAMS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/exams/count')

export const INSITUTE_LESSON_PLANS_ENDPOINT = config.apiBaseURL.concat('/api/lesson-plans')
export const INSITUTE_LESSON_PLANS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/lesson-plans/count')


export const INSITUTE_FAMILIES_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/families')
export const INSITUTE_FAMILIES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/institutes/:instituteId/families/count')



export const INSITUTE_EXAM_GROUPS_ENDPOINT = config.apiBaseURL.concat('/api/exam-groups')
export const INSITUTE_EXAM_GROUPS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/exam-groups/count')



export const INSITUTE_TESTS_ENDPOINT = config.apiBaseURL.concat('/api/tests')
export const INSITUTE_TESTS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/tests/count')


export const INSITUTE_TEST_GROUPS_ENDPOINT = config.apiBaseURL.concat('/api/test-groups')
export const INSITUTE_TEST_GROUPS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/test-groups/count')



export const INSITUTE_ASSESSMENTS_ENDPOINT = config.apiBaseURL.concat('/api/assessments')
export const INSITUTE_ASSESSMENTS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/assessments/count')


export const INSITUTE_ASSESSMENT_GROUPS_ENDPOINT = config.apiBaseURL.concat('/api/assessment-groups')
export const INSITUTE_ASSESSMENT_GROUPS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/assessment-groups/count')

export const INSTITUTE_MEETINGS_ENDPOINT = config.apiBaseURL.concat('/api/meetings/search')
export const INSTITUTE_MEETINGS_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/meetings/count')

export const INSTITUTE_MEETING_INVITES_ENDPOINT = config.apiBaseURL.concat('/api/meetings/invites/search')
export const INSTITUTE_MEETING_INVITES_COUNT_ENDPOINT = config.apiBaseURL.concat('/api/meetings/invites/count')

export const EVENTS = Object.freeze({
    BALANCE_NEEDS_UPDATE: "BALANCE_NEEDS_UPDATE",
    PROFIT_NEEDS_UPDATE: "PROFIT_NEEDS_UPDATE",
    TOTAL_STUDENT_COUNT_NEEDS_UPDATE: "TOTAL_STUDENT_COUNT_NEEDS_UPDATE",
    TOTAL_EMPLOYEE_COUNT_NEEDS_UPDATE: "TOTAL_EMPLOYEE_COUNT_NEEDS_UPDATE"
})
export const EVENT =  new EventEmitter()