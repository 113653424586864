import { useMemo } from 'react';
import { Profile } from 'src/model/profile';
import { useAccountInstitute } from './use-authenticate';
// ----------------------------------------------------------------------
/**
 * 
 * @returns {{ 
 * id: number
 * createdAt: string
 * updatedAt: string
 * email: string
 * verified: boolean
 * role:{
 *  type: 'institute' | 'student' | 'parent' | 'employee'
 * }
 * meta: any[]
 * }}
 */
export function useInstitute() {
  const institute = useAccountInstitute();
  return  institute??{};
}

export function useInstituteID() {
  const {id} = useInstitute();
  const _id = useMemo(()=>id, [id])
  return _id;
}

export function useInstituteProfile() {
  const {meta} = useInstitute();
  const _meta = useMemo(()=>new Profile(meta??[]), [meta])
  return _meta;
}

