import axios from 'axios'
import { getAuthToken } from 'src/auth/services/auth'
import { config } from 'src/config'

/**
 * @type {import('axios').AxiosInstance & {isAxiosError: (error:any)=> boolean}}
 */
export const userHttpClient = axios.create({
    baseURL: config.apiBaseURL
})
userHttpClient.interceptors.request.use(async (req) =>{
    const userTokenResult = await getAuthToken()
    if(userTokenResult.ok){
        req.headers.Authorization = `Bearer ${userTokenResult.data}`
    }
    return req
})
userHttpClient.isAxiosError = axios.isAxiosError
