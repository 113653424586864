import { isObject } from "lodash"

const ProfileMetaNil = Symbol('undefined')
export class Profile {
  /**
   * @type {Array<{name: string; value: any; id: number}> | Record<string, any>}
   */
  #data = []

  constructor(data = []) {
    if (data instanceof Profile) {
      this.#data = data.#data
    } else if (Array.isArray(data)) {
      this.#data = [...data]
    }else if (isObject(data)) {
      this.#data = {...data}
    }
    else {
      this.#data = []
    }
  }

   get data(){
    return this. #data;
   }

  /**
   * 
   * @param {string} path 
   * @param {any} defaultValue 
   * @returns 
   */
  get(path, defaultValue = null) {
    const entry = this.getEntry(path)
    if (entry === ProfileMetaNil) {
      return defaultValue
    }
    return entry.value
  }

  /**
   * 
   * @param {string} path 
   * @param {any} value 
   * @returns 
   */
  set(path, value = null) {
    if (Array.isArray(this.#data)) {
      for (let i = 0; i < this.#data.length; i += 1) {
        const entry = this.#data[i]
        if (entry.name === path) {
          entry.value = value
          return this
        }
      }
      this.#data.push({
        name: path,
        value,
      })
    }
    this.#data[path]  = value
    return this
  }

  /**
  * 
  * @param {string} path 
  * @returns 
  */
  getAll(path) {
    const entries = this.getAllEntry(path)
    return entries.map((entry) => entry.value)
  }

  /**
   * 
   * @param {string} path 
   * @param {any} defaultValue 
   * @returns {{name: string; value: any; id: number} |  ProfileMetaNil}
   */
  getEntry(path) {
    if (Array.isArray(this.#data)) {
      for (let i = 0; i < this.#data.length; i += 1) {
        const entry = this.#data[i]
        if (entry.name === path) {
          return entry
        }
      }
    }
    else if (typeof this.#data[path] !== 'undefined') {
      return {value:this.#data[path]}
    }
    return ProfileMetaNil
  }

  /**
  * 
  * @param {string} path 
  * @param {any} defaultValue 
  * @returns {Array<{name: string; value: any; id: number}>}
  */
  getAllEntry(path) {
    const enries = []
    if (Array.isArray(this.#data)) {
      for (let i = 0; i < this.#data.length; i += 1) {
        const entry = this.#data[i]
        if (entry.name === path) {
          enries.push(entry)
        }
      }
    }
    else if (typeof this.#data !== 'undefined') {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry in this.#data) {
        if (entry === path) {
          enries.push({value: entry})
        }
      }
    }
    return enries
  }

  has(path) {
    return this.get(this.#data, path) !== ProfileMetaNil
  }
}