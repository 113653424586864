
import { useAccount } from 'src/auth';
import { useInstitute } from 'src/auth/hooks/use-institute';
import { useContext, useMemo } from 'react';
import { useAuthProps, useProfile } from 'src/auth/hooks/use-authenticate';
import { LicenseContext } from 'src/contexts/LicenseContext';
import { getOthersNavigation, getParentNavigation, getStudentNavigation, getTeacherNavigation } from '../config-navigation';
import { getInstituteNavigation } from '../institute-navigation';

// ----------------------------------------------------------------------

const useDashboardNavigation = ()=>{
  const account = useAccount()
  const profile = useProfile()
  const props = useAuthProps()
  const institute = useInstitute()
  const {allow} = useContext(LicenseContext);

  const navigations = useMemo(()=>{
    if(account?.role?.type === 'institute' || (account?.role?.type === 'employee'&& ['principal','management-staff'].includes (profile?.get('Employee Role')))){
      return getInstituteNavigation(institute, allow)
    }
    if(account?.role?.type === 'student'){
      return getStudentNavigation({...account, props}, institute)
    }
    if(account?.role?.type === 'parent'){
      return getParentNavigation({...account, props}, institute)
    }
    if(account?.role?.type === 'employee' && ['teacher'].includes (profile?.get('Employee Role'))){
      return getTeacherNavigation({...account, props}, institute)
    }
    return  getOthersNavigation(account, institute)
  },[account, props, institute, profile, allow])
  return navigations
}

export default useDashboardNavigation