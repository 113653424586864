import PropTypes from 'prop-types'
import { useMemo, useState, useEffect } from 'react';

import { useRouter } from 'src/routes/hooks';

import routes from 'src/utils/routes';

import { AuthDB } from 'src/config';
import { Profile } from 'src/model/profile';
import BootIndicator from 'src/sections/indicator/bootIndicator';
import { AuthContext, BootstrapFns } from './auth-context';
import { getAuthData, setAuthData, setAuthToken } from './services/auth';

///---------------------------------------------------------------------
/**
 * 
 * @description cache data here
 * @returns 
 */
const _bootstraps=[getAuthData('profile'),getAuthData('institute'),  getAuthData('account'), getAuthData('props')]
const [_cacheProfile,_cacheInstitute, _cacheAccount, _cacheProps] = _bootstraps
// ----------------------------------------------------------------------
export function AuthorizationProvider ({ children })  { 
    const [account, setAccount] = useState(null);
    const [institute, setInstitute] = useState(null);
    const [props, setProps] = useState({});
    const [bootstraped, setBootstraped] = useState(false);
    const [profile, setProfile] = useState(null);
    const router = useRouter();
   
    useEffect(()=>{
      _cacheProfile.then((result)=>{
       if(result.ok) setProfile(new Profile(result.data));
       console.log("profile", result)
      });
      _cacheAccount.then((result)=>{
        if(result.ok) setAccount(result.data);
        console.log("account", result)
       });
       _cacheInstitute.then((result)=>{
        if(result.ok) setInstitute(result.data);
        console.log("institute", result)
       });
       _cacheProps.then((result)=>{
        if(result.ok) setProps(result.data??{});
        console.log("props", result)
       });
      Promise.all([..._bootstraps])
      .then(()=>new Promise((r)=>setTimeout(r,300)))
      .then(()=>Promise.all(BootstrapFns.map((fn)=>fn())))
      .then(()=>setBootstraped(true))
      // const changes =  AuthDB.changes({
      //   filter (doc) {
      //     return doc.type === 'marsupial';
      //   }
      // }).on( "change", (change)=>{
         
      //    switch(change.id){
      //       case 'account':{

      //       }
      //    }
      // })

      // return ()=> changes.cancel()
    }, [])

    const providerValue = useMemo(()=>({ 
      institute,
      account,
      profile,
      props,
      /**
       * @type {React.Dispatch<any>}
       */
      setProfile:(value) =>{
        setProfile((_account)=>{
          value = typeof value ==='function'? value(_account):value
          const _profile =value instanceof Profile?value: new Profile(value)
          setAuthData('profile', _profile.data);
          return value
        })
      },
      setInstitute:(value) =>{
        setInstitute((_account)=>{
          value = typeof value ==='function'? value(_account):value
          setAuthData('institute', value);
          return value
        })
      },
      setAccount:(value) =>{
        setAccount((_account)=>{
          value = typeof value ==='function'? value(_account):value
          setAuthData('account', value);
          return value
        })
      },

      setProps:(value) =>{
        setProps((_props)=>{
          value = typeof value ==='function'? value(_props):value
          setAuthData('props', value);
          return value
        })
      },

      async login(token){
        await setAuthToken(token)
      },
      async logout(){
        try {
          
          await AuthDB.allDocs().then((doc)=>Promise.all(doc.rows.map((row)=>(AuthDB.remove(row.id, row.value.rev)))));
          setProps(null)
          setProfile(null)
          setInstitute(null)
          setAccount(null)
        } catch (_) { /* empty */ }
        router.replace(routes.LoginPage)
      }
     }),[account, props, institute, profile, router])
    return bootstraped? (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  ):(<BootIndicator/>)
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node,
};