import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { RouterLink } from 'src/routes/components';
import { useRouter, usePathname } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import routes from 'src/utils/routes';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar';

import { getAccountFullName, getAccountPhotoURL } from 'src/utils/user';
import { useAccount, useProfile } from 'src/auth/hooks/use-authenticate';
import { NAV } from './config-layout';
import useDashboardNavigation from './hooks/useDashboardNavigation';



// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const account = useAccount();
  const profile = useProfile();
  const router = useRouter()
  const upLg = useResponsive('up', 'lg');
  const navConfig = useDashboardNavigation()
  const onProfileClick = ()=>{
    router.push(routes.ProfilePage)
  }
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      onClick={onProfileClick}
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar src={getAccountPhotoURL(account, profile)} alt="photoURL" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{getAccountFullName(account, profile)}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {account.role?.name}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </Stack>
  );



  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4 }} />

      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

     
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const {items = []} = item ?? {}
  const pathname = usePathname();
  const [open, setOpen] = useState(false)
  
  const isExpandable = items && items.length > 0
  const active = isExpandable? item?.path && pathname.startsWith(item?.path): item?.path === pathname;
  function handleClick(e) {
    e.preventDefault()
    setOpen(!open)
  }
  const MenuItemChildren = isExpandable ? (
    <Collapse sx={{paddingLeft:'1.2rem'}} in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {item.items.map((subItem, index) => (
          <NavItem item={subItem} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null
  const style= {
    minHeight: 44,
    borderRadius: 0.75,
    typography: 'body2',
    color: 'text.secondary',
    textTransform: 'capitalize',
    fontWeight: 'fontWeightMedium',
    ...(active && {
      color: 'primary.main',
      fontWeight: 'fontWeightSemiBold',
      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
      '&:hover': {
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
      },
    }),
  }
  const RootMenuItem =  isExpandable?(
    <ListItemButton
      onClick={(e)=>handleClick(e)}
      href={item.path}
      component={RouterLink}
      sx={style}
      disabled = {item.disabled}
    >
      {item.icon&& <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>}
      <Box component="span">{item.title}</Box>

      <Box component="span" sx={{ width: 24, height: 24, mr: 2, right:0, position:'absolute' }}>{open? <Iconify icon="eva:arrow-ios-upward-fill"/>: <Iconify icon="eva:arrow-ios-downward-fill"/>}</Box>
    </ListItemButton>
  ):(
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={style}
      disabled = {item.disabled}
    >
      {item.icon&&  <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>}
      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );


  return (
  <>
  {RootMenuItem}
  {MenuItemChildren}
  </>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
