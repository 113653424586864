import { CustomIcon } from "src/components/icon";
import Iconify from "src/components/iconify";
import routes from "src/utils/routes";
import { icon } from "./icon";


export const getDashbordNav  = ()=>(
    {
        title: 'dashboard',
        path: routes.HomePage,
        icon: icon('ic_analytics'),
    }
)
export const getGeneralSettingsNav = () => ({
    title: 'General Settings',
    path: routes.GeneralSettingsPage,
    icon: <Iconify icon='eva:settings-outline' />,
    items: [
        {
            title: 'Institution Profile',
            path: routes.InstitutionProfilePage,
        },
        {
            title: 'Fee Particulars',
            path: routes.InstitutionFeeParticularsPage,
        },
        {
            title: 'Details For Fee Challan',
            path: routes.InstitutionDetailsForFeeChallanPage,
        },
        {
            title: 'Rules & Regulations',
            path: routes.InstitutionRulesAndRegulationsPage,
        },
        {
            title: 'Marks Grading',
            path: routes.InstitutionMarksGradingPage,
        },
        {
            title: 'Academic Settings',
            path: routes.InstitutionAcademicSettingsPage,
        },
        {
            title: 'Account Settings',
            path: routes.InstitutionAccountSettingsPage,
        },
    ]
})


export const getAttendanceNav = () => ({
    title: 'Attendance',
    path: routes.AttendancesPage,
    icon: <CustomIcon icon='png:im.im_school' />,
    items: [
        {
            title: 'Mark Students Attendance',
            path: routes.MarkStudentsAttendancePage,
        },
        {
            title: 'Mark Employees Attendance',
            path: routes.MarkEmployeesAttendancePage,
        },

        {
            title: 'Class wise Report',
            path: routes.ClasswiseReportPage,
        },

        {
            title: 'Employees Attendance Report',
            path: routes.EmployeesAttendanceReportPage,
        },
        {
            title: 'Students Attendance Report',
            path: routes.StudentsAttendanceReportPage,
        },


    ]
})


export const getClassNav = () => ({
    title: 'Classes',
    path: routes.ClassesPage,
    icon: <Iconify icon='eva:book-outline' />,
    items: [
        {
            title: 'All Classes',
            path: routes.ClassAllPage,
        },
        {
            title: 'New Class',
            path: routes.ClassAddNewPage,
        },
    ]
})





export const getSubjectNav = () => (
        {
            title: 'Subjects',
            path: routes.SubjectPage,
            icon: icon('ic_user'),
            items:[
              {
                title: 'Classes with Subjects',
                path:  routes.ClassWithSubjectsPage,
              },
              {
                title: 'Assign Subjects',
                path:  routes.AssignClassToSubjectsPage,
              },
            ]
          }
    )


    export const getLessonNav = () => ( {
        title: 'Lessons',
        path: routes.LessonsPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'Lesson Plans',
            path:  routes.LessonPlanAllPage,
          }
        ]
      })

      export const getStudentNav = () => (  {
        title: 'Students',
        path: routes.StudentPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'All Student',
            path:  routes.StudentAllPage,
          
          },
          {
            title: 'Add New',
            path:  routes.StudentAddNewPage,
        
          },
          {
            title: 'Manage Families',
            path:  routes.ManageFamiliesPage,
        
          },
          {
            title: 'Admission Letter',
            path:  routes.StudentAdmissionLetterPage,
         
          },
          {
            title: 'Student ID Cards',
            path:  routes.StudentIdCardPage,
           
          },
          {
            title: 'Promote Students',
            path:  routes.StudentPromotionPage,
          
          }
        ]
      })


      export const getParentNav = () => ( {
        title: 'Parents',
        path: routes.ParentsPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'All Parent',
            path:  routes.ParentAllPage,
           
          },
          {
            title: 'Add New',
            path:  routes.ParentAddNewPage,
           
          },
          {
            title: 'Manage Families',
            path:  routes.ManageFamiliesPage,
         
          },
          {
            title: 'Parent Visit Cards',
            path:  routes.ParentIdCardsPage,
           
          }
        ]
      })



      export const getEmployeeNav = () => ( {
        title: 'Employees',
        path: routes.EmployeesPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'All Employee',
            path:  routes.EmployeeAllPage,
          },
          {
            title: 'Add New',
            path:  routes.EmployeeAddNewPage,
           
          },
          {
            title: 'Staff ID Cards',
            path:  routes.EmployeeStaffIdCardsPage,
          },
          {
            title: 'Job Letter',
            path:  routes.EmployeeJobLetterPage,
          }
        ]
      })


      export const getAccountNav = () => (  {
    title: 'Accounts',
    path: routes.AccountsPage,
    icon: icon('ic_user'),
    items:[
      {
        title: 'Chart Of Account',
        path:  routes.ChartOfAccountAllPage,
    
      },
      {
        title: 'Add Income',
        path:  routes.AddIncomePage,
      
      },
      {
        title: 'Add Expense',
        path:  routes.AddExpensePage,
      
      },
      {
        title: 'Account Statement',
        path:  routes.AccountStatementPage,
      
      }
    ]
  })
  export const getAssessmentNav = () => (  {
        title: 'Assessments',
        path: routes.AssessmentsPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'Create New Assessment',
            path:  routes.AssessmentAddNewPage,
          },
          {
            title: 'All Assessment',
            path:  routes.AssessmentAllPage,
          },
         
          {
            title: 'Add/Update Assessment Group',
            path:  routes.AssessmentGroupAllPage, 
          },
          {
            title: 'Assign Assessment to Group',
            path:  routes.AssignAssessmentToGroupPage, 
          },
        ]
      })
      export const getExamNav = () => (
      {
        title: 'Exams',
        path: routes.ExamsPage,
        icon: icon('ic_user'),
        items:[
          {
            title: 'Create New Exam',
            path:  routes.ExamAddNewPage,
          },
          {
            title: 'All Exam',
            path:  routes.ExamAllPage,
          },
          {
            title: 'Add/Update Exam Mark',
            path:  routes.ExamMarkingPage, 
          },
          {
            title: 'Add/Update Exam Group',
            path:  routes.ExamGroupAllPage, 
          },
          {
            title: 'Assign Exam to Group',
            path:  routes.AssignExamToGroupPage, 
          },
          {
            title: 'Result Card',
            path:  routes.ExamResultPage,
           
          }
        ]
      })


      export const getMessagingNav = ({institute}) => ( {
        title: 'Messaging',
        path: routes.MessagesPage,
        icon: <Iconify icon='eva:settings-2-outline'/>,
        items: [{
          title: 'Institute Chat Room',
          path: routes.ChatRoomPage.replace(':room_id', `institute::${institute.id}`)
        },
        {
          title: 'Direct Messaging',
          path: routes.DirectMessagingPage
        }]
      })
      export const getLiveClassNav = ({institute}) => (
      {
        title: 'Live Class',
        path: routes.SettingsPage,
        icon: <Iconify icon='eva:settings-2-outline'/>,
        items: [{
          title: 'Institute Live Class',
          path: routes.LiveClassRoomPage.replace(':room_id', `institute::${institute.id}`)
        }]
      })


      export const getTimetableNav = () => (
        {
            title: 'Timetable',
            path: routes.TimetablesPage,
            icon: <CustomIcon icon='png:im.im_school'/>,
            items:[
              {
                title: 'Weekdays',
                path:  routes.WeekdaysTimetablePage,
             
              },
              {
                title: 'Time Period',
                path:  routes.TimePeriodTimetablePage,
              
              },
              {
                title: 'Class Rooms',
                path:  routes.ClassRoomsTimetablePage,
               
              },
              {
                title: 'Create Timetable',
                path:  routes.CreateTimetablePage,
               
              },
              {
                title: 'Generate For Class',
                path:  routes.ClassTimetablePage,
               
              },
              {
                title: 'Generate For Teacher',
                path:  routes.TeacherTimetablePage,
               
              },
            ]
          }
    );

    export const getReportNav = () => (
       
          {
            title: 'Reports',
            path: routes.ReportsPage,
            icon: <CustomIcon icon='png:im.im_school'/>,
            items:[
              {
                title: 'Students report Card',
                path:  routes.ReportCardPage,
             
              },
              // {
              //   title: 'Students info report',
              //   path:  routes.WalletFundingPage,
               
              // },
              // {
              //   title: 'Parents info report',
              //   path:  routes.WalletFundingPage,
              
              // },
              // {
              //   title: 'Students Monthly Attendance Report',
              //   path:  routes.WalletFundingPage,
              // },
              // {
              //   title: 'Staff Monthly Attendance Report',
              //   path:  routes.WalletFundingPage,
              
              // },
              // {
              //   title: 'Fee Collection Report',
              //   path:  routes.WalletFundingPage,
               
              // },
              // {
              //   title: 'Student Progress Report',
              //   path:  routes.WalletFundingPage,
               
              // },
              // {
              //   title: 'Accounts Report',
              //   path:  routes.WalletFundingPage,
               
              // },
            ]
          }
    )